import { functions } from '@/util'
const DEBUG = false

const iconMap = {}
export function install(app) {
  // Load the icons.
  const icons = import.meta.glob('./*.svg', { eager: true })
  Object.entries(icons).forEach(([fileName, iconModule]) => {
    const iconName = functions.parsePath(fileName).name
      .replace(/^\.\//, '')
      .replace(/\.svg$/, '')
    const componentName = `YbNodeIcon_${iconName}`
    iconMap[componentName] = app.component(componentName, iconModule.default)
  })
  DEBUG && console.log('iconMap', iconMap)
}

export default {
  methods: {
    icon(name) {
      // The list of components is on this mixin; look for our mixin's components here to see if we need a fallback icon.
      const candidateIconName = `YbNodeIcon_${name}`
      if (candidateIconName in iconMap) {
        return candidateIconName
      } else {
        return 'YbNodeIcon_placeholder'
      }
    }
  }
}
